import axios from "axios";

import store from '@/store'
import { Message } from 'element-ui'
import router from '@/router'

import { headerToken, apiCode, whiteList, timeout } from '@/config'

var CancelToken = axios.CancelToken;
var source = CancelToken.source();

//请求中接口
let requestingList = [];
export default {
  init() {
    axios.defaults.timeout = timeout
    axios.interceptors.request.use((config) => {
      if (!config) {
        source.cancel(`发送了一个没有地址的请求`);
        return false;
      }
      // 拦截二次请求
      if (
        requestingList.includes(config.url) &&
        !whiteList.includes(config.url)
      ) {
        config.cancelToken = source.token;
        source.cancel(`请求过于频繁，拦截URL为：${config.url}`);
      } else {
        requestingList.push(config.url);
      }
      //添加token
      const token = store.state.TOKEN
      if (token) {
        config.headers[headerToken.tokenName] = token
      }
      // store.state.TOKEN
      // store.commit('SET_USERNAME', '')
      return config;
    });
    axios.interceptors.response.use(
      (result) => {
        if (result.config) {
          requestingList = requestingList.filter((item) => {
            return item !== result.config.url;
          });
        }
        // console.log("result: ", result)
        //返回状态拦截处理
        const code = Number(result.data?.code)
        console.log(code);
        
        if (code === apiCode.normal || code === 2000 )  {
          return result.data
        }
        if (code === apiCode.empower) {
          Message({
            message: result.data.msg,
            type: 'warning'
          })
        } else if (code === apiCode.noLogin || code === apiCode.errorToken) {
          //登录过期  || token验证失败
          // Message.error(result.data.msg)
          Message({
            message: result.data.msg,
            type: 'warning'
          })
          setTimeout(function () {
            store.commit('TOKEN', '')
            router.replace('/login')
          }, 3000)
        } else {
          // if (code === apiCode.error) 
          //异常提示
          Message.error(result.data.msg)
        }
      },
      (err) => {
        if (err.config) {
          requestingList = requestingList.filter((item) => {
            return item !== err.config.url;
          });
        }
        return Promise.reject(err);
      }
    );

    return axios;
  },
};
