/*
 * @Description: 接口文档
 * @FilePath: \vue2\src\apis.js
 */
var apis = {
  user_login: "api/user/public/login", //登录接口
  //订单绑定
  order_add: "order/binding/add",
  order_upd: "order/binding/upd",
  order_del: "order/binding/del",
  order_list: "order/binding/list",
  //定金(押金)
  deposit_config: "deposit/config", //存取 配置文件
  deposit_list_list: "deposit/list/index",
  deposit_list_del: "deposit/list/del",
  deposit_list_upd: "deposit/list/upd",
  //提现受理
  deposit_cash_list: "deposit/cash/index",
  deposit_cash_del: "deposit/cash/del",
  deposit_cash_upd: "deposit/cash/upd",
  deposit_cash_byDepositId: "deposit/cash/byDepositId",
  //推荐码
  agent_config: "agent/config",
  agent_addCode: "agent/addCode",
  agent_updAgent: "agent/updAgent",
  agent_delAgent: "agent/delAgent",
  agent_agentBan: "agent/agentBan",
  agent_list: "agent/list",
  agent_goodsList: "agent/goodsList",
  agent_orderList: "agent/orderList",
  agent_recordList: "agent/recordList",

  //号商
  merchant_add: "merchant/add",
  merchant_upd: "merchant/upd",
  merchant_del: "merchant/del",
  merchant_list: "merchant/list",

  //商品列表
  goods_upd: "goods/upd",
  goods_list: "goods/list",

  //包赔证明
  webapi_GetCertificateInfoByOrderNumberAsync: "webapi/CertificateInfo/GetCertificateInfoByOrderNumberAsync",
  webapi_GuaranteeCertificatePdfCreate: "webapi/CertificateInfo/GuaranteeCertificatePdfCreate",
  webapi_getAllCompensationTypes: "webapi/CertificateInfo/getAllCompensationTypes",
  webapi_GetPagedListAsync: "webapi/CertificateInfo/GetPagedListAsync",
  webapi_SoftDeleteAsync: "webapi/CertificateInfo/SoftDeleteAsync",
  webapi_download: "webapi/CertificateInfo/download",


  webapi_Cmf_UserComp_List: "webapi/Cmf_UserComp/GetByStep",
  webapi_Cmf_UserComp_Creat: "webapi/Cmf_UserComp/Create",
  webapi_Cmf_UserComp_AdminApprove: "webapi/Cmf_UserComp/AdminApprove",
  webapi_Cmf_UserComp_AdminUpdate: "webapi/Cmf_UserComp/AdminUpdate",


  //代理人模块java 
  // auth_addTopAgent: "recommend/api/agent/auth/addTopAgent",
  // auth_editBaseAgent: "recommend/api/agent/auth/editBaseAgent",
  // auth_topAgentTree: "recommend/api/agent/top-agent-tree",
  // auth_toggleAgentBan: "recommend/api/agent/auth/toggleAgentBan",
  // auth_addAgent: `recommend/api/agent/auth/addAgent_2`,
  // auth_addAgent: `recommend/api/agent/auth/addAgent_2`,
  // auth_delAgentById: `recommend/api/agent/auth/delAgentById`,
  // auth_getSubAgentByUserId: `recommend/api/agent/getSubAgentByUserId`,
  // admin_commissionRate_all: "recommend/api/admin/commission-rate/all",
  // admin_commissionRate_update: "recommend/api/admin/commission-rate/update",
};

Object.keys(apis).forEach((key) => {
  let url = apis[key]
  
   if (url.indexOf('api') == 0) {
    url = `/php/${url}`
  } 
  else if (url.indexOf('recommend') == 0) {

  } else if (url.indexOf('webapi') == 0) {

  } else {
    url = `/www/${url}`
  }
  
  apis[key] = url;
});

export default apis;
