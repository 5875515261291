import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: 'welcome',
    children: [
      //欢迎页
      {
        path: "welcome",
        name: "welcome",
        component: () => import("@/views/welcome.vue"),
        meta: {
          title: "欢迎页",
          isChecking: true,
        },
      },
      {
        path: "binding",
        name: "binding",
        component: () => import("@/views/order/binding"),
        meta: {
          title: "订单绑定",
        },
      },
      {
        path: "merchant",
        name: "merchant",
        component: () => import("@/views/merchant/index"),
        meta: {
          title: "号商",
        },
      },
      {
        path: "goods",
        name: "goods",
        component: () => import("@/views/goods/index"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "auth",
        name: "auth",
        component: () => import("@/views/auth/index"),
        meta: {
          title: "包赔信息",
        },
      },
      {
        path: "prove",
        name: "prove",
        component: () => import("@/views/prove/index"),
        meta: {
          title: "包赔证明",
        },
      },
      {
        path: "agent",
        name: "agent",
        component: () => import("@/views/agent/index"),
        meta: {
          title: "推荐人员",
        },
      },
      {
        path: "agentGoods",
        name: "agentGoods",
        component: () => import("@/views/agent/goods"),
        meta: {
          title: "商品推荐码",
        },
      },
      {
        path: "agentOrder",
        name: "agentOrder",
        component: () => import("@/views/agent/order"),
        meta: {
          title: "订单推荐码",
        },
      },
      {
        path: "agentRecord",
        name: "agentRecord",
        component: () => import("@/views/agent/record"),
        meta: {
          title: "佣金流水",
        },
      },
      {
        path: "depositList",
        name: "depositList",
        component: () => import("@/views/deposit/list"),
        meta: {
          title: "流水记录",
        },
      },
      {
        path: "depositCash",
        name: "depositCash",
        component: () => import("@/views/deposit/cash"),
        meta: {
          title: "提现受理",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      isChecking: true, //是否跳过路由检测
    },
    component: () => import("@/views/login"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from '@/store'
import { Message } from "element-ui";
// import { Message } from 'element-ui'

//白名单路由 | 路由配置 isChecking = true
const pathUrl = ['/login']

router.beforeEach((to, from, next) => {
  // console.log('路由：',to)
  NProgress.start();
  //路由拦截
  const token = store.state.TOKEN
  const path = to.path
  // console.log(store)
  // debugger
  if (pathUrl.includes(path) || to.meta.isChecking) {
    next()
  } else if (token) {
    //验证管理员权限
    // if (to.meta.isAdmin) {
    //   // debugger
    //   if (Number(store.state.USER.type) !== 1) {
    //     // Message.error('暂无权限！')
    //     Message({
    //       message: '暂无权限！',
    //       type: 'warning'
    //     })
    //     return
    //   }
    // }
    next();
  } else {
    Message({
      message: '登录过期！',
      type: 'warning'
    })
    router.replace('/login')
  }
});
router.afterEach(() => {
  NProgress.done();
});

export default router;
